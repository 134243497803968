import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-909b7c30"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "video-list learn-list d-flex",
  ref: "div1"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "item-bottom bg-white"
};
const _hoisted_4 = {
  class: "item-bottom-intro"
};
import { ref } from 'vue';
import Video from '../components/Video.vue';
export default {
  __name: 'ResList',
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  setup(__props) {
    const props = __props;
    let veoRefs = ref([]);
    const ua = window.navigator.userAgent;
    let isMobile = false;
    if (ua.indexOf('iPhone') >= 0) isMobile = true;
    if (ua.indexOf('Android') >= 0) isMobile = true;
    if (ua.indexOf('iPad') >= 0) isMobile = true;
    const setRef = el => {
      // console.log(veoRefs)
      if (el) veoRefs.value.push(el);
    };
    const onPlaying = (e, idx) => {
      console.log(e, idx);
      veoRefs.value.forEach((element, elementIndex) => {
        if (elementIndex !== idx) {
          element.videoPause();
        }
      });
    };
    const toUrl = url => {
      if (url) location.href = url;
    };
    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_card = _resolveComponent("el-card");
      return _openBlock(), _createBlock(_component_el_card, {
        class: "box-card"
      }, {
        default: _withCtx(() => [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, idx) => {
          return _openBlock(), _createElementBlock("div", {
            class: "list-item",
            key: idx
          }, [_createElementVNode("div", {
            class: "item_box",
            onClick: $event => toUrl(item.articleUrl)
          }, [!item.vedioSrc ? (_openBlock(), _createBlock(_component_el_image, {
            key: 0,
            class: "img",
            fit: "cover",
            src: item.poster
          }, null, 8, ["src"])) : (_openBlock(), _createBlock(Video, {
            key: 1,
            width: _unref(isMobile) ? '100%' : '480px',
            height: _unref(isMobile) ? '4rem' : '250px',
            ref_for: true,
            ref: setRef,
            src: item.vedioSrc,
            second: 0,
            onOnPlaying: $event => onPlaying($event, idx),
            preload: "none",
            poster: item.poster
          }, null, 8, ["width", "height", "src", "onOnPlaying", "poster"])), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(item.intro), 1)])], 8, _hoisted_2)]);
        }), 128))], 512)])]),
        _: 1
      });
    };
  }
};